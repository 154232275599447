<template>
  <section id="hero">
    <v-img
      :src="require('@/assets/Home/header.jpg')"
      class="white--text"
      gradient="rgba(5, 11, 31, .1), rgba(5, 11, 31, .3)"
      height="100%"
      max-height="890px"
    >
      <v-container class="fill-height px-4 py-0">
        <v-responsive
          height="100%"
          width="100%"
        >
          <v-row justify="center">
              <v-card :height="logoSize" :width="logoSize" elevation="6" class="mx-0 white  px-2" :style="styleLogo">
                <v-img
                  :width="logoSize"
                  :height="logoSize"
                  :aspect-ratio="1"
                  contain
                  :src="require('@/assets/Todo-carta-logo.png')"
                  class="mt-2"
                ></v-img>
              </v-card>
          </v-row>
          <v-row justify="center" class="mt-1">
            <v-col cols="12" class="text-center pb-0">
              <span class="title-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">TU CARTA DIGITAL</span>
            </v-col>
          </v-row>
          <v-row justify="center" align="end" class="mt-md-4 mt-1 mt-lg-4">
            <v-col cols="12" class="text-center d-inline-flex justify-center py-0">
              <div class="slogan-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 4px;">
                UNA MARCA DE
              </div>
                <a href="http://audituxinformatica.com" class="ma-0 pa-0"><img :src="require('@/assets/Home/AudituxLogo.svg')" alt="" :width="audituxSize.width" :height="audituxSize.height"></a>
              <div class="slogan-landing white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}" style="margin-top: 3px; padding-left: 4px;">
               INFORMÁTICA</div>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2">
            <v-col cols="12" class="d-flex justify-center mt-1">
              <v-btn outlined class="btn-landing" :large="$vuetify.breakpoint.mdAndUp" color="white" :to="{name: 'login'}">Área de clientes</v-btn>
            </v-col>
          </v-row>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.md ? '50vh' : this.$vuetify.breakpoint.lg ? '75vh' : this.$vuetify.breakpoint.xl ? '58vh' : '45hv';
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      logoSize () {
        const size = this.$vuetify.breakpoint.md ? '10' : this.$vuetify.breakpoint.lg ? '11' : this.$vuetify.breakpoint.xl ? '19' : '5'

        return `${size}rem`
      },
      styleLogo(){
        return {
           'margin-top': this.$vuetify.breakpoint.mdAndUp ? '-30px !important' : '-15px !important'
        }
      },
      audituxSize(){
        return {
          width: this.$vuetify.breakpoint.xl ? '192' : this.$vuetify.breakpoint.mdAndUp ? '112' :  '92',
          height: this.$vuetify.breakpoint.xl ?  '40' : this.$vuetify.breakpoint.mdAndUp ? '28' : '18',
        }
      }
    },
  }
</script>
<style lang="sass" scope>

  .title-landing
    font-weight: 100
    line-height: 1.15em
    font-family: Roboto, sans-serif !important
    &.lg-view
      letter-spacing: 0.4em !important
      font-size: 3.0125rem !important
    &.sm-view
      letter-spacing: 0.15em !important
      font-size: 1.8rem !important
    &.xl-view
      letter-spacing: 0.6em !important
      font-size: 5.0125rem !important

  .slogan-landing
    line-height: 1.4em
    font-weight: 100
    font-family: Roboto, sans-serif !important
    color: #2c2a2b !important
    &.lg-view
      font-size: 1.025rem !important
      letter-spacing: 0.2em !important
    &.sm-view
      font-size: 0.525rem !important
      letter-spacing: 0.1em !important
    &.xl-view
      font-size: 1.8rem !important
      letter-spacing: 0.3em !important

  .title-landing-section
    font-weight: 700
    line-height: 1.15em
    font-family: Museo-700 !important
    color: #2c2a2b !important
    &.lg-view
      letter-spacing: 0.4em !important
      font-size: 2.8125rem !important
    &.sm-view
      letter-spacing: 0.15em !important
      font-size: 2rem !important
    &.xl-view
      letter-spacing: 0.6em !important
      font-size: 5.9125rem !important

  .slogan-landing-section
    line-height: 1.4em
    font-weight: 400
    font-family: Roboto, sans-serif !important
    color: #2c2a2b !important
    &.lg-view
      font-size: 1.825rem !important
      letter-spacing: 0.2em !important
    &.sm-view
      font-size: 1.025rem !important
      letter-spacing: 0.1em !important
    &.xl-view
      font-size: 2.9rem !important
      letter-spacing: 0.3em !important

  .title-saving
    font-weight: 700
    line-height: 1.15em
    font-family: Museo-700 !important
    color: #2c2a2b !important
    &.lg-view
      font-size: 2rem !important
    &.sm-view
      font-size: 1rem !important
    &.xl-view
      font-size: 2.8125rem !important

  .description-saving
    line-height: 1.4em
    font-weight: 400
    font-family: Roboto, sans-serif !important
    color: #2c2a2b !important
    &.lg-view
      font-size: 1.525rem !important
    &.sm-view
      font-size: 1.025rem !important
    &.xl-view
      font-size: 2.4rem !important

  .custom-title
    line-height: 1.4em
    font-weight: 100
    font-size: 1.125rem !important
    color: #292626 !important
    padding-top: 15px
  .btn-landing
    font-family: Montserrat, sans-serif !important
    font-weight: 600
</style>
